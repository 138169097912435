.disturbanceMessageContent {
    overflow-y: scroll;
    height: 75rem;
}
.new-message-button {
    display: flex;
    justify-content: flex-end;
    padding-top: 5rem;
    flex:0.2 !important;
}
.filter-container {
    flex: 0.8 !important;
    display: flex;
    align-items: center;
    z-index:2;
    flex-wrap: wrap;
}
.filter-container .shared-components-dropdown{
    z-index:auto !important;
}
.filter-container .input-container{
    flex-wrap: nowrap;  
} 
.filter-container .option-container{
    z-index: 2 !important;
    max-height:80rem;
}
.filter-dropdown {
    margin: 3rem;
    display: flex;
    align-items: center;
}
.filter-label{
    margin-left: 4rem;    
}
.content-wrapper .sdds-btn.sdds-btn-sm{
    /* for better responsiveness */
    height: fit-content; 
}
.close-icon {
    cursor: pointer;
    margin-left: 1rem;
}
#new-disturbance-message-modal .react-datepicker__navigation--previous {
    border: none;
}

#new-disturbance-message-modal .react-datepicker__navigation--next {
    border: none;
}
input,
textarea {
    color: black !important;
}
.filter-dropdown input, .filter-dropdown button{
    background-color: #F9FAFB!important;
    font-family: inherit;
}
.inputWrapper svg{
    left: inherit;
    right: 20px;
}