/* Data table */
.table {
  border: 0;

  border-collapse: collapse;
  word-break: normal;
  margin-bottom: 1rem;
  text-align: left;
  width: 100%;
}

.table thead {
  display: table-header-group;
}
.table thead>tr>th:first{
  width:90px;
}
.table thead>tr>th {
  vertical-align: middle;
  line-height: 4rem;
  padding: var(--sdds-spacing-element-16);
  color: var(--sdds-grey-900);
  background-color: var(--sdds-grey-300);
  border-bottom: 1px solid var(--sdds-grey-400);
}

.table tbody {
  color: var(--sdds-grey-900);
  background-color: var(--sdds-white);
}

.table tbody>tr>td {
  padding: var(--sdds-spacing-element-12);
  vertical-align: middle;
  border-bottom: 1px solid var(--sdds-blue-100);
}

.table tbody>tr.inactive>td {
  color: var(--sdds-blue-100);
  border-bottom: 1px solid var(--sdds-blue-200);
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
