.badge {
    display: inline-block;
    width: 8rem;
    height: 8rem;
    text-align: center;
    filter: invert(35%) sepia(91%) saturate(851%) hue-rotate(188deg) brightness(91%) contrast(95%);
}

.badgeDanger {
    filter: invert(24%) sepia(63%) saturate(6079%) hue-rotate(346deg) brightness(93%) contrast(86%);
}

.badgeWarning {
    filter: invert(63%) sepia(54%) saturate(662%) hue-rotate(7deg) brightness(113%) contrast(89%);
}

.badgeOK {
    width: 11rem;
    height: 11rem;
    margin-left: -1.5rem;
    filter: invert(56%) sepia(99%) saturate(349%) hue-rotate(116deg) brightness(81%) contrast(89%);
}
.iconWrapper{
    width:32px;
    height:32px;
    overflow: hidden;
}

.iconWrapper > .badgeOK {
    margin-top: -7px;
}