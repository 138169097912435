#new-disturbance-message-modal .sdds-modal-body {
  min-height: 130rem;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
#new-disturbance-message-modal .sdds-checkbox-item .sdds-form-input {
  padding: 0 !important;
  padding-left: 1rem;
  border: 0px !important;
  height: 3.5rem;
}

#new-disturbance-message-modal h4,
#new-disturbance-message-modal h5,
#new-disturbance-message-modal h6 {
  margin-top: var(--sdds-spacing-layout-8);
  margin-bottom: var(--sdds-spacing-layout-8);
}

#new-disturbance-message-modal .react-datepicker-wrapper {
  display: block;
}
#new-disturbance-message-modal .react-datepicker__input-container input {
  border-radius: 1rem 1rem 0 0;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  border: none;
  outline: none;
  height: 100%;
  color: var(--sdds-textfield-color);
  background-color: var(--sdds-textfield-bg);
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial,
    Helvetica, sans-serif;
  font-size: 3.5rem;
  line-height: 4rem;
  letter-spacing: -0.01em;
  padding: var(--sdds-spacing-element-16);
}

/*
      https://github.com/Hacker0x01/react-datepicker/issues/624
  */
#new-disturbance-message-modal .react-datepicker {
  font-size: 1em;
}
#new-disturbance-message-modal .react-datepicker__month {
  margin: 0.4em 1em;
}
#new-disturbance-message-modal .react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
#new-disturbance-message-modal .react-datepicker__current-month {
  font-size: 1em;
}
#new-disturbance-message-modal .react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
#new-disturbance-message-modal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
#new-disturbance-message-modal .react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}
/* #new-disturbance-message-modal .react-datepicker__time-container {
    width: auto !important;
  } */
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: auto !important;
}

.react-datepicker__header--time {
  padding-top: 36px;
}

.react-datepicker-popper {
  z-index: 1000;
}

#new-disturbance-message-modal .sdds-dropdown-menu {
  z-index: 10000 !important;
}

#new-disturbance-message-modal CustomModal {
  padding: 20rem;
}
#new-disturbance-message-modal {
  background-color: white;
  padding: 16px;
}
.datepick svg {
  left: 92% !important;
}
.react-datepicker__header--time {
  padding-top: 10px;
  padding-bottom: 36px;
}
.react-datepicker__time-container {
  width: 141px !important;
}
.drop input {
  background-color: #f9fafb !important;
}
.drop button {
  background-color: #f9fafb !important;
}

.review-message-container{
  font-size: 3.5rem;
}
.review-message-container .sdds-card{
  background-color: #F9FAFB!important;
}

#time-display-info1, #time-display-info2{
font-size: 5rem;
}

.label-size{
  font-size:3.5rem;
  margin-bottom: 2rem;
  font-family: inherit;
}

.mod-col{
  padding:0 !important;
}
.stepper-placeholder{
  font-size: 4rem;
  
}
.sticky-position {
  position: sticky !important;
  top: 0px;
  z-index: 1000;
}
.sticky-position {
  position: sticky !important;
  top: 0px;
  z-index: 1000;
}
.sticky-dropdown{
  position: sticky !important;
  top: 230px;
  z-index: 1000;
}
.utc-conversion{
  font-size: 3.5rem;
  color: grey;
  margin-top:2rem;
  margin-bottom:8rem;
}

.add-message-modal-screen #toast-component-container .sdds-toast {
padding-bottom: 3rem !important;
}
.edit-message-container .sdds-col{
 padding-left:0 !important;
}


