.profile-drop-item{
    --sdds-link-focus-border: 0 !important;
}
.profile-info{
    min-width:60rem !important;    
    width: auto !important;
}
.profile-info.sdds-navbar-menu-item-dropdown>.sdds-navbar-menu-item-link{
    justify-content: center !important;
}
.profile-dropdown{
    text-align: center !important;
}
.drop-item{    
    min-width: 76rem !important;
}

