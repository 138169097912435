.content-wrapper {    
    padding-bottom: var(--sdds-spacing-layout-24);
}
.component-wrapper {
    padding-bottom: var(--sdds-spacing-element-24);
}
h1{
    font-size: 7rem !important;
    display: inline;
}
.title{
    flex:6 !important;
}

.mt-8 { margin-top: var(--sdds-spacing-layout-8); }
.mb-8 { margin-bottom: var(--sdds-spacing-layout-8); }
.mr-8 { margin-right: var(--sdds-spacing-layout-8); }
.ml-8 { margin-left: var(--sdds-spacing-layout-8); }

.mt-16 { margin-top: var(--sdds-spacing-layout-16); }
.mb-16 { margin-bottom: var(--sdds-spacing-layout-16); }
.mr-16 { margin-right: var(--sdds-spacing-layout-16); }
.ml-16 { margin-left: var(--sdds-spacing-layout-16); }

.mt-24 { margin-top: var(--sdds-spacing-layout-24); }
.mb-24 { margin-bottom: var(--sdds-spacing-layout-24); }
.mr-24 { margin-right: var(--sdds-spacing-layout-24); }
.ml-24 { margin-left: var(--sdds-spacing-layout-24); }

.mt-32 { margin-top: var(--sdds-spacing-layout-32); }
.mb-32 { margin-bottom: var(--sdds-spacing-layout-32); }
.mr-32 { margin-right: var(--sdds-spacing-layout-32); }
.ml-32 { margin-left: var(--sdds-spacing-layout-32); }

.mt-48 { margin-top: var(--sdds-spacing-layout-48); }
.mb-48 { margin-bottom: var(--sdds-spacing-layout-48); }
.mr-48 { margin-right: var(--sdds-spacing-layout-48); }
.ml-48 { margin-left: var(--sdds-spacing-layout-48); }

.pt-8 { padding-top: var(--sdds-spacing-layout-8); }
.pb-8 { padding-bottom: var(--sdds-spacing-layout-8); }
.pr-8 { padding-right: var(--sdds-spacing-layout-8); }
.pl-8 { padding-left: var(--sdds-spacing-layout-8); }



.sdds-nav__overlay {
    z-index: 3000 !important;
}

.sdds-nav__app-launcher-menu,
.sdds-nav__avatar-menu {
    z-index: 3001 !important;
}

.sdds-nav__avatar-item {
    padding: 5rem !important;
    cursor: pointer;
}

.sdds-nav .sdds-nav__avatar-info {
    padding: 0 !important;
    cursor: auto;
}

.sdds-nav__avatar-item>a {
    text-decoration: none !important;
    color: black !important;
}
