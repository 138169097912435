h5 {color: #0D0F13!important;}
p {color: #0D0F13!important;font-size: 3.5rem; font-weight: 400;}

.userInformation {
    background-color: #F9FAFB;
    padding: 10px;
    color: #0D0F13!important;
    border-radius: 2px;
}
.infoTitle { 
    font-size: 3.5rem;
    font-weight: 700;
    color: #56657A;
 }
.informations {
    display: grid;
    grid-template-columns: 40ch auto;
    padding: 10px 0px;
    color: #0D0F13!important;
}
.details {
    display: grid;
    grid-template-columns: 11ch auto ;
}
.Settings {
    padding: 10px;
    padding-top:5rem;
}
.language {
    display: flex;
}
.language > p  {
    padding: 0 8rem 0 0;
}
.role {
    display: flex;
}
.role > p  {
    padding: 0 25px 0 0;
}
.unit {
    display: flex;
    color: #0d0f13;
    font-size: 3.5rem; 
    font-weight: 400;
    align-items: baseline;
}

.radioButtons {
    display: flex;
    flex-direction: column;
    margin-left: 15rem;
}

.informations input, .informations button{
    background-color: #F9FAFB!important;
    font-family: inherit;
}

