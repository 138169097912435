.paginationWrapper {
    display: flex;
    justify-content: center;
    margin: 6rem;
}
.pagination_number {
    height: 8rem;
    width: 8rem;
    background-color: white;
    border:1px solid #DBDFE6;
    cursor: pointer;
}
.pagination_number:hover {
    background-color: #DBDFE6;
}
.page_active {
    height: 8rem;
    width: 8rem;
    border:1px solid #DBDFE6;
    cursor: pointer;
    background-color: #DBDFE6;
}
.page_disabled {   
    border: 1px solid #b0b7c4;
    height: 8rem;
    width: 8rem;
    opacity: 0.2;
}
.arrow {
    height: 4rem;
    margin-top: 4px
} 