.wrapper {
    display: flex;
    width: 130rem;
    justify-content: center;
    color: #D6DADE;
    font-size: 12px;
    white-space: nowrap;
    margin:auto;
    padding-bottom: 15px;
}
.step {
    display: flex;
    text-align: -webkit-center;
    width: 33.33%;
    color: #D6DADE;
}
.stepLast {
    display: flex;
    text-align: -webkit-center;
}
.step:after {
    content: "";
    display: block;
    width: 300px;
    height: 0.5px;
    background: #D6DADE;    
    margin-top: 25px;
}
.circle {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
    background: #fff;
    border: 1px solid #D6DADE;
}

.active {
    color: #0D0F13;
}
.circle_active {
    color: #0D0F13;
    border: 1px solid #0D0F13;
}
.done {
    color: white;
    background-color: #1DAB8B;
    border: none;
}
.done + p {
    color: #0D0F13;
}
.done > img {
    vertical-align: middle;
}
