.dataIconSize {
    font-size: 2em !important;
    visibility: inherit;
    margin-top: -1.2rem;
}

.settingsIconSize {
    font-size: 1.7em !important;
    visibility: inherit;
    margin-top: -1.8rem;
}

.statusIconSize {
    height: 1em;
    visibility: inherit;
}

.makeClickableLink {
    cursor: pointer;
    color: #2058A8;
    width: fit-content;
}

.sortingArrow {
    margin-left: 2rem;

}

.createdByFieldSize {
    width: 50rem;
}

.actionFieldSize {
    width: 22rem;
}

.timeFieldSize {
    cursor: pointer;
    width: 47rem;
}
.statusField{
    width:5.6rem;
}
.titleFieldSize {
    width: 100rem;
}

.typeFieldSize {
    width: 20rem;
}

.systemFieldsize {
    cursor: pointer;
    width: 40rem;
}

.pointer {
    cursor: pointer;
    min-width: 90px;
}

.messageInfo {
    display: flex;   
    padding-right: 16rem;
    position: sticky !important;
    top: 0px;
    z-index: 999;
    background-color:white;
    padding-top: 3rem;
    padding-left: 4rem;
    height: 15rem;
}

.systemName {
    flex-grow: 1;
    
}

.label {
    font-weight: bold;
    margin-right: 2rem;
};

#disturbance-message-contents-modal  .sdds-modal-body {
    min-height: 150rem;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }